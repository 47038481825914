
import { defineComponent } from 'vue'
import ChatsSettingsPage from '@/components/pages/chats/settings/ChatsSettingsPage.vue'
import TmAvatar from '@/components/shared/TmAvatar.vue'
import DetailsHero from '@/components/shared/templates/details/DetailsHero.vue'
import InfoCards from '@/components/shared/templates/InfoCards.vue'
import ChatInstagramGeneral from '@/components/pages/chats/settings/instagram/detailed/ChatInstagramGeneral.vue'
import ChatInstagramSharing from '@/components/pages/chats/settings/instagram/detailed/ChatInstagramSharing.vue'
import ChatInstagramUnlink from '@/components/pages/chats/settings/instagram/detailed/ChatInstagramUnlink.vue'
import { instagramMock } from '@/definitions/chats/settings/instagram/data'
import { formatDate } from '@/services/dateTimeService'

export default defineComponent({
  components: {
    ChatsSettingsPage,
    TmAvatar,
    DetailsHero,
    InfoCards,
    ChatInstagramGeneral,
    ChatInstagramSharing,
    ChatInstagramUnlink,
  },
  setup() {
    const breadcrumbs = [
      { label: 'Messenger', name: 'base.chats.main' },
      { label: 'Messenger settings', name: 'base.chats.settings' },
      { label: 'Instagram', name: 'base.chats.settings.instagram' },
      { label: 'Acme LLC (Australia)' },
    ]

    const instagramAccount = instagramMock[0]

    return {
      breadcrumbs,
      instagramAccount,
      formatDate,
    }
  },
})
