
import { defineComponent } from 'vue'
import InfoCard from '@/components/shared/templates/InfoCard.vue'
import InfoCardRow from '@/components/shared/templates/InfoCardRow.vue'

export default defineComponent({
  components: {
    InfoCard,
    InfoCardRow,
  },
  props: {
    username: {
      type: String,
    },
  },
})
